<template>
    <app-drawer :menus="menus" v-if="project" color="light-blue darken-4" />
</template>

<script>
import { mapGetters } from 'vuex';
import appDrawer from './drawer';

export default {
    name: 'drawer-project',
    components: {
        appDrawer,
    },
    computed: {
        ...mapGetters(['project', 'profile']),
        allMenus() {
            return [
                {
                    to: 'project-issues',
                    icon: 'mdi-bug',
                    label: 'drawer.projects.project-issues',
                    position: 'top',
                    roles: ['productOwner', 'productManager', 'developer', 'support', 'reporter'],
                },
                {
                    to: 'project-versions',
                    icon: 'mdi-package-variant',
                    label: 'drawer.projects.project-versions',
                    position: 'top',
                    versions: true,
                    roles: ['productOwner', 'productManager', 'developer', 'support', 'reporter'],
                },
                {
                    to: 'project-migration',
                    icon: 'mdi-clipboard-text-multiple-outline',
                    label: 'drawer.projects.project-migration',
                    position: 'top',
                    versions: true,
                    roles: ['productOwner', 'productManager', 'developer', 'support'],
                },
                {
                    to: 'project-roadmap',
                    icon: 'mdi-map-legend',
                    label: 'drawer.projects.project-roadmap',
                    position: 'top',
                    versions: true,
                    roles: ['productOwner', 'productManager', 'developer', 'support', 'reporter'],
                },
                {
                    to: 'project-backlog',
                    icon: 'mdi-arrow-decision-outline',
                    label: 'drawer.projects.project-backlog',
                    position: 'top',
                    roles: ['productOwner', 'productManager', 'developer'],
                },
                /*{
                    to: 'project-docs',
                    icon: 'mdi-book-open-variant',
                    label: 'drawer.projects.project-docs',
                    position: 'top',
                    roles: ['productOwner', 'productManager', 'developer', 'support', 'reporter', 'reader'],
                },*/
                /*{
        to: 'project-testing',
        icon: 'mdi-clipboard-list-outline',
        label: 'drawer.projects.project-testing',
        position: 'top',
        roles: ['productOwner', 'productManager', 'developer'],
    },*/
                {
                    to: 'project-analytics',
                    icon: 'mdi-chart-multiple',
                    label: 'drawer.projects.project-analytics',
                    position: 'top',
                    roles: ['productOwner', 'productManager'],
                },
                {
                    to: 'project-settings',
                    icon: 'mdi-tune-vertical',
                    label: 'drawer.projects.project-settings',
                    position: 'bottom',
                    roles: ['productManager'],
                },
            ];
        },
        projectUser() {
            if (this.project) {
                return this.project.members.filter((m) => m.user === this.profile.name)[0];
            }
            return undefined;
        },
        hasProjectVersions() {
            if (this.project) {
                return this.project.versions.length > 0;
            }
            return true;
        },
        menus() {
            const menus = this.allMenus.filter((m) => {
                if (!this.hasProjectVersions && m.versions === true) {
                    return false;
                }
                if (this.profile.security.administration) {
                    return true;
                }
                if (m.roles && this.projectUser) {
                    return m.roles.some((r) => this.projectUser.roles.includes(r));
                } else {
                    return true;
                }
            });

            return menus;
        },
    },
};
</script>
